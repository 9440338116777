import React, {useCallback} from 'react';
import axios from 'axios';
import {sendContactRequestEndpoint} from "../constants/api";

function useSendContactRequest() {
  const sendContactRequest = useCallback(async (requestBody) => {
    const {name, phone, section, carId, utm} = requestBody;
    const response = await axios.post(sendContactRequestEndpoint, {
      name,
      phone,
      section,
      carId,
      utm: JSON.stringify(utm)
    }, { crossDomain: true })
    return response.data;
  }, []);

  return {sendContactRequest}
}

export default useSendContactRequest;

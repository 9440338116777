export const apiBaseUrl = 'https://api.carfind.com.ua/api';

export const apiPublicBaseUrl = 'https://api.carfind.com.ua';

export const carsListEndpoint = `${apiBaseUrl}/cars/list?catalogue=true`;
export const carsListMainEndpoint = `${apiBaseUrl}/cars/list-main`;

export const carsBrandsEndpoint = `${apiBaseUrl}/cars/brands`;

export const electricCarsListEndpoint = `${apiBaseUrl}/cars/electric`;

export const sendContactRequestEndpoint = `${apiBaseUrl}/cars/request`;

export const getCurrentBannerEndpoint = `${apiBaseUrl}/cars/banners`;

export const carFindLiveEndpoint = `${apiBaseUrl}/events/list`;

export const singleCarEndpoint = (id) => `${apiBaseUrl}/cars/${id}`;

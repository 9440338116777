import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import {Box, Flex, Heading, Text, Button} from "rebass/styled-components";
import {sectionHeadingStyles, VideoContainer, VideoOverlay} from "../Home/Home";
import {apiPublicBaseUrl, singleCarEndpoint} from "../../constants/api";
import toTitleCase from "../../util/toTitlecase";
import Car from "../Catalogue/components";
import {useTranslation} from "next-i18next";
import useCarMapping from "../../hooks/useCarMapping";
import {useRouter} from "next/router";
import Link from 'next/link'
import Image from 'next/image'

const ElectricCars = ({screenWidth: width, electricCars, electricCar}) => {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const mapCar = useCarMapping();
  const videoContainerRef = useRef();

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  const [mappedElectricCars, setMappedElectricCars] = useState([]);
  const [mappedElectricCar, setMappedElectricCar] = useState([]);

  const [mainTitle, setMainTitle] = useState(t('electricCarsTitle'));

  useEffect(() => {
    if (electricCars.length) {
      const mappedCars = electricCars.map(mapCar);
      const teslaCars = mappedCars.filter(c => c.brand === 'Tesla');
      const restCars = mappedCars.filter(c => c.brand !== 'Tesla').sort((a, b) => a.brand > b.brand ? 1 : -1);
      setMappedElectricCars([...teslaCars, ...restCars]);
    }
  }, []);

  useEffect(() => {
    if (electricCar) {
      setMappedElectricCar(mapCar(electricCar));
    }
  }, [])

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
          overflow: 'hidden'
        }}
      >
        <PageContent title>
          <MainTitle
            as='h1'
            fontFamily='secondary'
            isLaptop={width <= laptopWidth}
            isMobile={width <= mobileWidth}
            isSmMobile={width <= smMobileWidth}
          >
            {mainTitle}
          </MainTitle>
        </PageContent>

        <VideoOverlay/>
        <VideoContainer ref={videoContainerRef}>
          {width <= mobileWidth && (
            <video poster="/static-web/videos/tesla_mobile.mp4" id="bgvid" playsInline autoPlay loop muted>
              <source src="/static-web/videos/tesla_mobile.mp4" type="video/webm"/>
              <source src="/static-web/videos/tesla_mobile.mp4" type="video/ogv"/>
              <source src="/static-web/videos/tesla_mobile.mp4" type="video/mp4"/>
            </video>
          )}

          {width > mobileWidth && (
            <video poster="/static-web/videos/tesla_compressed.mp4" id="bgvid" playsInline autoPlay loop muted>
              <source src="/static-web/videos/tesla_compressed.mp4" type="video/webm"/>
              <source src="/static-web/videos/tesla_compressed.mp4" type="video/ogv"/>
              <source src="/static-web/videos/tesla_compressed.mp4" type="video/mp4"/>
            </video>
          )}
        </VideoContainer>
      </Section>

      {!electricCar && electricCars.length > 0 && (
        <>
          <Section
            isSmMobile={width <= smMobileWidth}
            id='section-2'
            fullWidth
          >
            <Text
              sx={{
                ...sectionHeadingStyles,
                mb: '50px !important'
              }}
            >
              <Heading
                variant='heading.xl'
                color='text.secondary'
                fontFamily='secondary'
                sx={{
                  '@media(max-width: 600px)': {
                    fontSize: 28
                  }
                }}
              >
                {t('electricCarsWhyUsTitle')}
              </Heading>
            </Text>

            <PageContent>
              <IconsWrapper>
                <IconItem>
                  <Image alt='electric-car' width={75} height={75} src='/static-web/images/electric-cars/electric-car.svg' />
                  <TextBlock>
                    <Text fontWeight='bold' fontFamily='primaryBold'>{t('electricCarsLabel1Title')}</Text>
                    <Text>{t('electricCarsLabel1Content')}</Text>
                  </TextBlock>
                </IconItem>
                <IconItem>
                  <Image alt='repair' width={75} height={75} src='/static-web/images/electric-cars/repair.svg' />
                  <TextBlock>
                    <Text fontWeight='bold' fontFamily='primaryBold'>{t('electricCarsLabel2Title')}</Text>
                    <Text>{t('electricCarsLabel2Content')}</Text>
                  </TextBlock>
                </IconItem>
                <IconItem>
                  <Image alt='battery' width={75} height={75} src='/static-web/images/electric-cars/battery.svg' />
                  <TextBlock>
                    <Text fontWeight='bold' fontFamily='primaryBold'>{t('electricCarsLabel3Title')}</Text>
                    <Text>{t('electricCarsLabel3Content')}</Text>
                  </TextBlock>
                </IconItem>
                <IconItem>
                  <Image alt='software' width={75} height={75} src='/static-web/images/electric-cars/soft.svg' />
                  <TextBlock>
                    <Text fontWeight='bold' fontFamily='primaryBold'>{t('electricCarsLabel4Title')}</Text>
                    <Text>{t('electricCarsLabel4Content')}</Text>
                  </TextBlock>
                </IconItem>
                <IconItem>
                  <Image alt='station' width={75} height={75} src='/static-web/images/electric-cars/station.svg' />
                  <TextBlock>
                    <Text fontWeight='bold' fontFamily='primaryBold'>{t('electricCarsLabel5Title')}</Text>
                    <Text>{t('electricCarsLabel5Content')}</Text>
                  </TextBlock>
                </IconItem>
              </IconsWrapper>
            </PageContent>
          </Section>

          <Section
            isSmMobile={width <= smMobileWidth}
            id='section-2'
            fullWidth
            bgColor={'#e4e4e4'}
          >
            <Text
              sx={{
                ...sectionHeadingStyles
              }}
            >
              <Heading
                variant='heading.xl'
                color='text.secondary'
                fontFamily='secondary'
                sx={{
                  '@media(max-width: 600px)': {
                    fontSize: 28
                  }
                }}
              >
                {t('electricCarsTitle')}
              </Heading>
            </Text>

            <PageContent>
              <ElectricCarsWrapper>
                {mappedElectricCars.length > 0 && mappedElectricCars.map(ec => (
                  <CarWrapper key={`ec-${ec.uuid}`}>
                    <Flex
                      sx={{
                        height: '40px',
                        alignItems: 'center'
                      }}
                    >
                      <CarTitle fontFamily="primary" color='text.secondary'>{toTitleCase(ec.brand)} {toTitleCase(ec.model)}</CarTitle>
                    </Flex>
                    <CarThumb>
                      <CarImage height={265} width={320} src={`${apiPublicBaseUrl}/${ec.images[0]}`} />
                    </CarThumb>
                    <Flex justifyContent='center' mt={4} mb={2}>
                      <Button
                        variant='contained'
                      >
                        <Link
                          href={`/electric-cars/${ec.uuid}`}
                        >
                          {t('electricCarsDetailsButtonText')}
                        </Link>
                      </Button>
                    </Flex>
                  </CarWrapper>
                ))}
              </ElectricCarsWrapper>
            </PageContent>
          </Section>
        </>
      )}

      {electricCar && (
        <Car width={width} id={mappedElectricCar.uuid} presentationElectric setMainTitle={setMainTitle}/>
      )}
    </>
  );
};

const CarImage = styled(Image)`
  height: 265px !important;
  width: 100% !important;
  object-fit: contain !important;
  max-height: 265px !important;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 700px) {
    height: 200px !important;
  }
`

const CarThumb = styled(Flex)`
  position: relative;
  width: 100%;
  height: fit-content;
`

const CarTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  padding: 5px 0;

  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

const CarWrapper = styled(Flex)`
  flex-direction: column;
  box-shadow: 0 12px 32px -6px rgb(21 34 66 / 10%), 0 0 1px rgb(21 34 66 / 22%);
  background: #fff;
  padding: 10px;
  min-height: 400px;
  flex-wrap: nowrap;
  max-width: 350px;
  min-width: 265px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 700px) {
    min-height: fit-content;
  }
  
  &:hover {
    box-shadow: 0 12px 32px -6px rgb(21 34 66 / 20%), 0 0 1px rgb(21 34 66 / 32%);
    cursor: pointer;
  }

  ${({ isSmMobile }) => isSmMobile && css`
    min-width: 235px;
  `}
`

const ElectricCarsWrapper = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
  margin-top: 50px;
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const TextBlock = styled(Flex).attrs(() => ({
  fontFamily: 'primary'
}))`
  flex-direction: column;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
`

const IconItem = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  img {
    height: 75px;
  }
`

const IconsWrapper = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 25px;
  margin-top: 25px;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const MainTitle = styled(Heading)`
  font-weight: bold;
  position: absolute;
  color: #fff;
  right: 3%;
  max-width: 100%;
  text-transform: uppercase;
  padding: 0 60px;
  font-size: 58px;
  line-height: 60px;
  top: 70%;

  @media (max-width: 1600px) {
    left: 9%;
  }

  //@media (max-width: 1200px) {
  //  right: 45px;
  //}

  ${({isLaptop}) => isLaptop && css`
    padding: 12px;
    top: 175px;
  `}

  ${({isMobile}) => isMobile && css`
    padding: 0;
    font-size: 36px;
    line-height: 46px;
    top: 100px;
  `}

  ${({isSmMobile}) => isSmMobile && css`
    font-size: 28px;
    max-width: 100%;
    text-align: center;

    left: 50% !important;
    width: 100%;
    transform: translateX(-50%);
  `}
`;

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 100;
  flex-direction: column;
  
  @media (max-width: 1600px) {
    padding: ${({ theme }) => `0 ${theme.pageGutters[4]}`};
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
  ${({title}) => title && css`
    position: absolute;
    height: 100%;
  `}
  
`

const CarHeaderImage = styled(Image)`
  width: 100%;
  max-height: 820px;
  object-fit: cover;
  min-height: 325px;
  position: relative;
  @media (max-width: 1000px) {
    min-height: 325px;
  }
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  min-height: 325px;
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '75px 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};
  background: ${({bgColor}) => bgColor ? bgColor : '#fff'};

  :not(:first-child) {
    margin: 0 auto;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}
`

ElectricCars.propTypes = {
  electricCars: PropTypes.array
};

ElectricCars.defaultProps = {
  electricCars: []
}

export default ElectricCars;

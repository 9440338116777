export const toTitleCase = (str) => {
  if (str) {
    if (str.toLowerCase() === 'bmw') {
      return 'BMW'
    } else if (str.toLowerCase() === 'gmc') {
      return 'GMC'
    }
    else {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      )
    }
  }
  return ''
}

export default toTitleCase

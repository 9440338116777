import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from 'styled-components';
import {Flex, Text, Button, Box} from 'rebass/styled-components';
import axios from "axios";
import useCarMapping from "../../../../hooks/useCarMapping";
import "react-image-gallery/styles/css/image-gallery.css";
import {carStatuses, fuels} from "../../../../constants/carAttributesMap";
import {useModal} from "react-modal-hook";
import ReactModal from "react-modal";
import {apiPublicBaseUrl, singleCarEndpoint} from "../../../../constants/api";
import toTitleCase from "../../../../util/toTitlecase";
import useSendContactRequest from "../../../../hooks/useSendContactRequest";
import {useTranslation, i18n} from "next-i18next";
import {useRouter} from 'next/router'
import InputMask from 'react-input-mask';
import Link from 'next/link';
import { ToastContainer, toast } from 'react-toastify';
import {Oval} from "react-loader-spinner";
import dynamic from 'next/dynamic';
import validator from "validator";

const ImageGallery = dynamic(() => import('react-image-gallery'), {
  ssr: false,
});

const CallbackModalComponent = ({hideModal, onSaveClick}) => {

  const {t} = useTranslation('common');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '500px',
      width: '100%'
    },
  };

  const [formValues, setFormValues] = useState({
    name: '',
    phone: ''
  })

  const sendRequest = useCallback(() => {
    onSaveClick({
      name: formValues.name,
      phone: formValues.phone
    })
    hideModal();
  }, [hideModal, formValues, onSaveClick]);

  const handleInputChange = useCallback((event) => {
    const {name, value} = event.target
    setFormValues({
      ...formValues,
      [name]: value
    })
  }, [formValues])

  return (
    <ReactModal
      isOpen
      style={customStyles}
    >
      <FormWrapper
        sx={{
          '@media(max-width: 600px)': {
            padding: '0 !important'
          }
        }}
      >
        <Text
          variant='body.md'
          sx={{
            fontWeight: 'bold',
            mb: '25px',
            maxWidth: 320,
            textAlign: 'center',
            alignSelf: 'center'
          }}
        >
          {t('footerFormTitle')}
        </Text>
        <Input type='text' name='name' onChange={handleInputChange} placeholder={t('footerFormNameInput')}/>
        <InputMask mask="+38\0 99 999 99 99" onChange={handleInputChange}>
          {(inputProps) => <Input {...inputProps} name='phone' placeholder={t('footerFormNumberInput')} />}
        </InputMask>
        <Button
          variant='outlined'
          color='text.black'
          onClick={sendRequest}
          sx={{
            borderRadius: 12
          }}
        >
          {t('footerFormButton')}
        </Button>

        <Button
          variant='borderless'
          color='text.black'
          onClick={hideModal}
          sx={{
            borderRadius: 12,
            mt: 2
          }}
        >
          {t('footerFormButtonBack')}
        </Button>
      </FormWrapper>
    </ReactModal>
  )
}

const Input = styled.input`
  border: 2px solid #000;
  padding: 20px 25px;
  border-radius: 12px;
  margin-bottom: 10px;
  width: 100%;
  height: 30px;
  background-color: inherit;
  font-size: 16px;
  font-family: ${({theme}) => theme.fonts.primary};
`

const FormWrapper = styled(Flex)`
  flex-direction: column;
  padding: 25px 25px 0;
  justify-content: center;

  ${({isMobile}) => isMobile && css`
    padding: 0;
  `}
`

const Car = ({id, width, presentationElectric = false, setMainTitle}) => {

  const theme = useTheme();
  const mapCar = useCarMapping();
  const {t} = useTranslation('common');
  const {push} = useRouter();
  const {sendContactRequest} = useSendContactRequest();

  const {language: resolvedLanguage} = i18n || {};

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  const [mappedCar, setMappedCar] = useState();
  const [currentCar, setCurrentCar] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [carImages, setCarImages] = useState([]);

  const sendRequest = useCallback(async ({name, phone}) => {
    const utm = {
      ...(JSON.parse(window.sessionStorage.getItem('utm_marks') || {}))
    }
    window.fbq('track', 'Lead', {utm})
    if (typeof window !== 'undefined') {
      console.log(currentCar)
      window.gtag("event", "Клик", {
        event_category: "Номер телефона",
        event_name: "Клик",
        event_label: "Каталог",
        value: "",
      });
      window.dataLayer.push({
        action: "Клик",
        event_category: "Номер телефона",
        event_label: "Каталог",
        value: ``,
      })
    }
    const isPhoneNumberValid = validator.isMobilePhone(phone.replace(/\s/g, ''), 'uk-UA');
    if (isPhoneNumberValid) {
      await sendContactRequest({
        name,
        phone,
        section: presentationElectric ? 'Електрокари' : 'Каталог',
        carId: id,
        utm
      });
      toast(t('callbackToastText'));
      localStorage.setItem('userFormData', JSON.stringify({
        name: name,
        phone: phone,
      }))
      push('/thank-you', undefined, { shallow: true })
    } else {
      toast(t('invalidNumberToastText'));
    }
  }, [id, presentationElectric, sendContactRequest, t, currentCar]);

  const [showModal, hideModal] = useModal(() => (
    <CallbackModalComponent hideModal={hideModal} onSaveClick={sendRequest}/>
  ));

  const goToCatalogue = useCallback((params = {}) => {
    if (Object.keys(params).length) {
      let urlString = '/';
      let titleString = '';
      for (const key in params) {
        urlString += `${key}=${encodeURIComponent(params[key])}/`;
        titleString += ``;
      }
      setMainTitle(t('cataloguePageTitle'))
      push(`/catalogue${urlString.slice(0, -1)}`)
    } else {
      setMainTitle(t('cataloguePageTitle'))
      push(`/catalogue`)
    }
  }, [push]);

  useEffect(() => {
    if (id && id !== currentCar?.uuid) {
      axios.get(singleCarEndpoint(id), {crossDomain: true})
        .then(responseData => {
          const mappedCar = mapCar(responseData.data.car);
          setCurrentCar(responseData.data.car);
          setMappedCar(mappedCar);
          if (resolvedLanguage === 'ua') {
            setMainTitle(`${mappedCar.carBodyType} ${mappedCar.brand} ${mappedCar.model} ${mappedCar.year} з США та Канади`)
          } else {
            setMainTitle(`${mappedCar.carBodyType} ${mappedCar.brand} ${mappedCar.model} ${mappedCar.year} из США и Канады`)
          }
          setCarImages(mappedCar.images.map(i => ({
            original: `${apiPublicBaseUrl}/${i}`,
            thumbnail: `${apiPublicBaseUrl}/${i}`,
            originalAlt: `${mappedCar.brand} ${mappedCar.model} ${mappedCar.year}`,
            thumbnailAlt: `${mappedCar.brand} ${mappedCar.model} ${mappedCar.year}`,
            originalTitle: `${mappedCar.brand} ${mappedCar.model} ${mappedCar.year}`,
            thumbnailTitle: `${mappedCar.brand} ${mappedCar.model} ${mappedCar.year}`,
          })))
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err, 'ERROR LOADING CAR');
          setIsLoading(false);
        })
    }
  }, [mapCar, id, currentCar?.uuid])

  return (
    <>
      <ToastContainer
        theme='dark'
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading && (
        <Flex
          sx={{
            height: '500px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Oval
            height="100"
            width="100"
            color='black'
            secondaryColor="grey"
            ariaLabel='loading'
          />
        </Flex>
      )}

      {!isLoading && mappedCar && (
        <Section
          isSmMobile={width <= smMobileWidth}
          id='section-2'
          fullWidth
        >
          <PageContent
            mt={presentationElectric ? 6 : 0}
          >
            {!presentationElectric && (
              <Breadcrumbs my={4}>
                <Breadcrumb onClick={() => {
                  goToCatalogue();
                }}>
                  {t('cataloguePageTitle')}
                </Breadcrumb>
                <BreadcrumbDivider/>
                <Breadcrumb
                  onClick={() => {
                    goToCatalogue({status: currentCar.status})
                  }}
                >
                  {mappedCar.status}
                </Breadcrumb>
                <BreadcrumbDivider/>
                <Breadcrumb
                  onClick={() => {
                    goToCatalogue({brand: currentCar.brand, status: currentCar.status})
                  }}
                >
                  {mappedCar.brand}
                </Breadcrumb>
                <BreadcrumbDivider/>
                <Breadcrumb
                  onClick={() => {
                    goToCatalogue({model: currentCar.model, brand: currentCar.brand, status: currentCar.status})
                  }}
                >
                  {toTitleCase(mappedCar.model)}
                </Breadcrumb>
                <BreadcrumbDivider/>
                <Breadcrumb
                  onClick={() => {
                    goToCatalogue({brand: currentCar.brand, model: currentCar.model, status: currentCar.status, year: currentCar.year})
                  }}
                >
                  {mappedCar.brand} {toTitleCase(mappedCar.model)} {mappedCar.year}
                </Breadcrumb>
              </Breadcrumbs>
            )}

            <CarWrapper>
              <Images
                isElectric={presentationElectric}
                css={`
                  .image-gallery-thumbnails-container {
                    display: flex;
                  }

                  .image-gallery {
                    width: 100%;
                  }

                  .image-gallery-content.fullscreen {
                    //top: 250px;
                  }

                  .image-gallery-thumbnail {
                    &.active {
                      border: 4px solid #dedede;
                    }

                    &:focus, &:hover {
                      border: 4px solid #dedede;
                    }

                    .image-gallery-thumbnail-image {
                      @media (min-width: 900px) {
                        height: 62px;
                        width: auto;
                      }
                    }
                  }

                  .image-gallery-icon {
                    color: #fff;
                  }

                  .image-gallery-play-button {
                    display: none;
                  }
                `}
              >
                <ImageGallery items={carImages}/>
              </Images>

              <MainInfo>
                <InfoTitle>
                  <Text
                    fontFamily='primary'>{toTitleCase(mappedCar.brand)} {toTitleCase(mappedCar.model)} {toTitleCase(mappedCar.trim)}</Text>
                </InfoTitle>
                <InfoPrice>
                  <Text fontFamily='primary'>
                    {presentationElectric ?
                      <Flex alignItems='end'>
                        <HintText>{t('carPagePriceFromLabel')}</HintText>
                        <ValueHintText>{mappedCar.price} {mappedCar.currency}</ValueHintText>
                      </Flex> :
                      <Text>{mappedCar.price} {mappedCar.currency}</Text>
                    }
                  </Text>
                  {/*<Button*/}
                  {/*  variant='outlined'*/}
                  {/*  color='text.black'*/}
                  {/*  sx={{*/}
                  {/*    borderRadius: 12,*/}
                  {/*    ml: 2*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Рассчитать стоимость таможенной очистки*/}
                  {/*</Button>*/}
                </InfoPrice>

                {presentationElectric && (
                  <InfoPrice
                    sx={{
                      mt: '0 !important'
                    }}
                  >
                    <Flex alignItems='end' fontFamily='primary'>
                      <HintText>{t('carPageBatteryCapacityFromLabel')}</HintText>
                      <ValueHintText lineHeight={'35px'}>{mappedCar.engineCapacity * 1000} кВт</ValueHintText>
                    </Flex>
                  </InfoPrice>
                )}

                {!presentationElectric && (
                  <>
                    <MainInfoItem>
                      <InfoItem>
                        <InfoItemLabel>{t('cataloguePageFilterTypeMileage')}</InfoItemLabel>
                        <InfoItemValue>{mappedCar.mileage} {resolvedLanguage === 'ua' ? 'тис' : 'тыс'} км</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>{t('carPageEngineLabel')}</InfoItemLabel>
                        {currentCar.fuel === fuels[2].value ?
                          <InfoItemValue>{mappedCar.fuel}, {mappedCar.engineCapacity * 1000} кВт</InfoItemValue> :
                          <InfoItemValue>{mappedCar.fuel}, {mappedCar.engineCapacity}л</InfoItemValue>
                        }
                      </InfoItem>
                    </MainInfoItem>

                    <MainInfoItem>
                      <InfoItem>
                        <InfoItemLabel>{t('cataloguePageFilterTypeTransmission')}</InfoItemLabel>
                        <InfoItemValue>{mappedCar.transmission}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>{t('cataloguePageFilterTypeWheelDriveType')}</InfoItemLabel>
                        <InfoItemValue>{mappedCar.wheelDriveType}</InfoItemValue>
                      </InfoItem>
                    </MainInfoItem>

                    <MainInfoItem>
                      <InfoItem>
                        <InfoItemLabel>{t('cataloguePageFilterTypeBodyType')}</InfoItemLabel>
                        <InfoItemValue>{mappedCar.carBodyType}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>{t('cataloguePageFilterTypeYear')}</InfoItemLabel>
                        <InfoItemValue>{mappedCar.year}</InfoItemValue>
                      </InfoItem>
                    </MainInfoItem>

                    <Vin>
                      <InfoItemLabel>VIN</InfoItemLabel>
                      <InfoItemValue>{mappedCar.vin}</InfoItemValue>
                    </Vin>
                  </>
                )}

                {presentationElectric && (
                  <Description>
                    <Text
                      fontFamily='primary'
                      dangerouslySetInnerHTML={{__html: mappedCar[`description_${resolvedLanguage}`]}}
                    />
                  </Description>
                )}

                {width > mobileWidth && (
                  <Flex
                    justifyContent={'space-between'}
                    mt={4}
                  >
                    <Button
                      variant='outlined'
                      // color='text.black'
                      onClick={showModal}
                      sx={{
                        borderRadius: 12,
                        background: 'red',
                        borderColor: 'red',
                        color: '#fff'
                      }}
                    >
                      {currentCar.status === carStatuses[0].value ?
                        <Text>{t('carPageBuyButtonText')}</Text> :
                        <Text>{t('carPageCallbackButtonText')}</Text>
                      }
                    </Button>

                    {presentationElectric && (
                      <Button
                        variant='outlined'
                        color='text.black'
                        sx={{
                          borderRadius: 12,
                        }}
                      >
                        <Link
                          href={`/electric-cars`}
                        >
                          {t('carPageBackButtonText')}
                        </Link>
                      </Button>
                    )}
                  </Flex>
                )}
              </MainInfo>

              {currentCar.status !== carStatuses[1].value && !presentationElectric && (
                <AdditionalInfo>
                  <AdditionalInfoItem>
                    <AdditionalInfoItemLabel>{t('carPageSafetyLabel')}</AdditionalInfoItemLabel>
                    <AdditionalInfoItemValues>
                      {mappedCar.safety?.map((s, i) => (
                        <>
                          <AdditionalInfoItemValue>{s}</AdditionalInfoItemValue>
                          {mappedCar.safety[i + 1] && <AdditionalInfoValueDivider/>}
                        </>
                      ))}
                    </AdditionalInfoItemValues>
                  </AdditionalInfoItem>
                  <AdditionalInfoItem>
                    <AdditionalInfoItemLabel>{t('carPageComfortLabel')}</AdditionalInfoItemLabel>
                    <AdditionalInfoItemValues>
                      {mappedCar.comfort?.map((c, i) => (
                        <>
                          <AdditionalInfoItemValue>{c}</AdditionalInfoItemValue>
                          {mappedCar.comfort[i + 1] && <AdditionalInfoValueDivider/>}
                        </>
                      ))}
                    </AdditionalInfoItemValues>
                  </AdditionalInfoItem>
                  <AdditionalInfoItem>
                    <AdditionalInfoItemLabel>{t('carPageBackMultimediaLabel')}</AdditionalInfoItemLabel>
                    <AdditionalInfoItemValues>
                      {mappedCar.multimedia?.map((m, i) => (
                        <>
                          <AdditionalInfoItemValue>{m}</AdditionalInfoItemValue>
                          {mappedCar.multimedia[i + 1] && <AdditionalInfoValueDivider/>}
                        </>
                      ))}
                    </AdditionalInfoItemValues>
                  </AdditionalInfoItem>
                </AdditionalInfo>
              )}

              {!presentationElectric && (
                <Description>
                  <Text
                    fontFamily='primary'
                    dangerouslySetInnerHTML={{__html: mappedCar[`description_${resolvedLanguage}`]}}
                  />
                </Description>
              )}

              {width <= mobileWidth && (
                <Flex justifyContent='center' flexDirection='column' mt={4}>
                  <Button
                    variant='outlined'
                    color='text.black'
                    onClick={showModal}
                    sx={{
                      borderRadius: 12,
                    }}
                  >
                    {currentCar.status === carStatuses[0].value ?
                      <Text>{t('carPageBuyButtonText')}</Text> :
                      <Text>{t('carPageCallbackButtonText')}</Text>
                    }
                  </Button>

                  {presentationElectric && (
                    <Button
                      variant='outlined'
                      color='text.black'
                      sx={{
                        borderRadius: 12,
                        mt: 2
                      }}
                    >
                      <Link
                        href={`/electric-cars`}
                      >
                        {t('carPageBackButtonText')}
                      </Link>
                    </Button>
                  )}
                </Flex>
              )}
            </CarWrapper>
          </PageContent>
        </Section>
      )}
    </>
  );
};

const ValueHintText = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  font-size: 28px !important;
  line-height: 32px;
`

const HintText = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  font-size: 22px !important;
  margin-right: 10px;
`

const AdditionalInfoValueDivider = styled(Flex)`
  height: 100%;
  width: 1px;
  background: #000;
  margin: 0 10px;
`

const AdditionalInfoItemLabel = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  font-weight: bold;
`

const AdditionalInfoItemValues = styled(Flex)`
  margin-top: 5px;
`;


const AdditionalInfoItemValue = styled(Text).attrs(() => ({
  fontFamily: 'primary',
}))`
`

const AdditionalInfoItem = styled(Flex)`
  flex-direction: column;
  margin-bottom: 20px;
`

const InfoItemValue = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`

const InfoItemLabel = styled(Text).attrs(() => ({
  fontFamily: 'primary',
  color: 'text.secondary'
}))`
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const Vin = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #000;
  padding-top: 10px;
`

const InfoItem = styled(Flex)`
  flex-direction: column;

  :nth-child(even) {
    justify-content: flex-end;
    text-align: right;
  }
`

const MainInfoItem = styled(Flex)`
  justify-content: space-between;
  border-top: 1px solid #000;
  margin: 10px 0 0 0;
  padding: 10px 0;
`

const InfoTitle = styled(Flex)`
  width: 100%;

  div {
    font-size: 32px;
    font-weight: bold;
  }
`

const InfoPrice = styled(Flex)`
  width: 100%;
  margin: 25px 0;

  div {
    font-size: 36px;
    font-weight: bold;
  }
`

const Images = styled(Flex)`
  align-items: ${({isElectric}) => isElectric ? 'flex-start' : 'center'};
`

const MainInfo = styled(Flex)`
  flex-direction: column;
`

const AdditionalInfo = styled(Flex)`
  flex-direction: column;
`

const Description = styled(Flex)`
`

const CarWrapper = styled(Flex)`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 25px;
  grid-row-gap: 50px;

  @media (max-width: 900px) {
    grid-template-columns: 100%;
  }
`

const Breadcrumbs = styled(Flex)`
  width: 100%;
  height: 20px;
  align-items: center;
  @media (max-width: 500px) {
    height: 14px;
  }
`

const Breadcrumb = styled(Text)`
  font-family: ${({theme}) => theme.fonts.primary};
  padding: 2px 0;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  font-size: 16px;
  color: inherit;

  &:hover {
    border-bottom: 1px solid #000;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }
`

const BreadcrumbDivider = styled(Flex).attrs(() => ({
  mx: 2
}))`
  height: 100%;
  width: 2px;
  background: #000;
  @media (max-width: 500px) {
    margin: 0 5px;
  }
`

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-width: 1600px;
  position: relative;
  z-index: 99;
  flex-wrap: wrap;

  @media (max-width: 1600px) {
    padding: ${({theme}) => `0 ${theme.pageGutters[4]}`};
  }

  @media (max-width: 1200px) {
    padding: 0;
  }

  @media (min-width: 1600px) {
    padding: 0 25px;
  }
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px 50px;
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};
  background: #e4e4e4;
  margin: 0 auto 0;

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}

`

Car.propTypes = {
  setMainTitle: PropTypes.func
};

Car.defaultProps = {
  setMainTitle: () => {}
}

export default Car;
